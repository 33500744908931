import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { HOME } from "../../../assets";
import splitStringUsingRegex from "../../../utils/splitStringUsingRegex";

// Default animation settings for text
const defaultAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

// AnimatedText Component

const AnimatedText = ({
  text = "",
  el: Wrapper = "p",
  className = "",
  isVisible = false,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isVisible, controls]);

  return (
    <Wrapper className={className}>
      <span className="sr-only">{text}</span>
      <motion.span
        variants={{
          visible: { transition: { staggerChildren: 0.09 } },
          hidden: {},
        }}
        initial="hidden"
        animate={controls}
        aria-hidden
      >
        {text.split("").map((char, charIndex) => (
          <motion.span
            variants={defaultAnimation}
            key={`${char}-${charIndex}`}
            className={`inline-block ${char === " " ? "" : ""}`}
          >
            {char}
          </motion.span>
        ))}
      </motion.span>
    </Wrapper>
  );
};

// Hero Component
export default function Hero() {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: false });
  const [secondRef, inSecond] = useInView({ triggerOnce: false });
  const heading = "Treasured Memories with Every Visit";
  const textdata =
    " Experience a seamless blend of luxury, business, and leisure, making every visit a treasured memory.";

  const headingChars = splitStringUsingRegex(heading);
  const textDataChars = splitStringUsingRegex(textdata);

  const charVariants = {
    hidden: { opacity: 0, y: 20 }, // Adjust animation properties as needed
    reveal: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (inSecond) {
      controls.start("textShow2");
    } else {
      controls.start("textHide2");
    }
  }, [controls, inSecond]);

  return (
    <div ref={ref} className="w-full h-auto relative">
      <div className="h-full w-full relative flex justify-center">
        <div className="absolute bg-back w-full h-full opacity-40"></div>
        <video
          className="object-cover w-full md:h-screen h-[555px]"
          loop
          autoPlay
          muted
          playsInline
        >
          <source src={HOME.VIDEO} type="video/mp4" />
        </video>
        <div className="absolute bottom-10 text-white w-full px-5 lg:px-20 text-center flex flex-col gap-5 md:gap-20 ">
          <motion.p
            initial="hidden"
            whileInView="reveal"
            transition={{ staggerChildren: 0.02 }}
            className="font-Dalmatins leading-[70px] lg:leading-[90px] text-xl md:text-2xl  lg:text-4xl"
          >
            {headingChars.map((char, index) => (
              <motion.span
                key={index}
                variants={charVariants}
                transition={{ duration: 0.8 }}
              >
                {char}
              </motion.span>
            ))}
          </motion.p>

          <motion.p
            initial="hidden"
            whileInView="reveal"
            transition={{ staggerChildren: 0.02 }}
            className="text-center px-2 md:px-20 lg:px-50 font-poppins text-sm md:text-lg lg:text-xl font-thin"
          >
            <h1>
              Welcome to Rustic Leisures - The Best Resort in Vypin, Kochi,
            </h1>
            {textDataChars.map((char, index) => (
              <motion.span
                key={index}
                variants={charVariants}
                transition={{ duration: 0.5 }}
              >
                {char}
              </motion.span>
            ))}
          </motion.p>
        </div>
      </div>
    </div>
  );
}
