import React from "react";
import { HOME } from "../../../assets";
import splitStringUsingRegex from "../../../utils/splitStringUsingRegex";
import { motion, Variants } from "framer-motion";

export default function Banner2() {
  const text =
    "Located in Puthuvype, near the Vypin Islands of Kochi, Kerala, Rustic Leisures is the perfect place for luxury, business, and leisure, meeting every need with ease. Experience the calm of coastal Kochi, with the convenience of being just moments away from the city’s lively culture. Rustic Leisures is situated in an ideal location for accessing all of Kochi's prime destinations, ensuring that everywhere you wish to visit in Kochi is within easy reach.";
  const textChars = splitStringUsingRegex(text);

  const charVariants = {
    hidden: { opacity: 0, y: 20 }, // Adjust animation properties as needed
    reveal: { opacity: 1, y: 0 },
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full grid md:grid-cols-2">
        <div className="md:col-span-1 w-full">
          <div className="w-auto lg:h-[770px] md:h-[580px] h-[400px]">
            <img
              src={HOME.BANNER_IMG2}
              className="w-full h-full object-cover"
              alt="Enjoy outdoor pools in Resort"
            />
          </div>
          
        </div>
        <div className="md:col-span-1 w-full bg-[#F6F9F8] flex md:justify-start md:pl-10 items-center justify-center">
          <div className="w-[85%] flex flex-col max-w-screen-2xl md:mr-[5%] md:py-0 py-15">
            <div className="xl:text-5xl lg:text-4xl md:text-2xl text-xl font-felixTitling">
              <p className="xl:mb-3 lg:mb-1">FINE RANGE OF</p>
              <p className="xl:mb-3 lg:mb-1">FACILITIES , AMENITIES</p>
              <p> AND ACTIVITIES</p>
            </div>
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="xl:text-[20px] lg:text-[16px] md:text-[15px] font-poppins mt-4 font-extralight"
            >
              {textChars.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char}
                </motion.span>
              ))}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}
