import React, { useEffect } from "react";
import Hero from "./Hero";
import Blog from "./Blog";
// import Footer from "./Footer";
import Banner from "./Banner";
import Banner2 from "./Banner2";
import Package from "./Package";
import Offers from "./Offers";
import InfoSection from "./InfoSection";
import { Helmet } from "react-helmet-async";

export default function HomeScreen() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full h-auto flex flex-col">
      <Helmet>
        <title>Luxury Hotel & Resort in Kochi , Kerala | Rustic Leisures</title>{" "}
        <meta
          name="description"
          content=" Experience luxury at Rustic Leisures, the best hotel & resort in Kochi, Kerala. Relax in elegant rooms and Indulge in spa treatments. Enjoy stunning sea views."
        />
        <link rel="canonical" href="https://rusticleisures.com/" />
      </Helmet>
      <Hero />
      <InfoSection />
      <Banner />
      <Banner2 />
      <Package />
      <Offers />
      <Blog />
      {/* <Footer /> */}
    </div>
  );
}
